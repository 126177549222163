.App {
  margin: 40px;
}

table {
  border-collapse: collapse;
}

table th {
  margin: 0px;
  padding: 4px;
  border: 1px solid #CCC;
  text-align: center;
  color: #FFF;
  background-color: #000;
}

table td {
  margin: 0px;
  padding: 4px;
  border: 1px solid #CCC;
  text-align: center;
}